<template>
    <div class="products-details-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="products-details-image">
                        <img src="../../assets/images/products-details.jpg" alt="image">
                        <div class="sale">Sale</div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="products-details-desc">
                        <h3>Technology Book</h3>
                        <div class="price">
                            <span class="new-price">$13.25</span>
                            <span class="old-price">$11.25</span>
                        </div>
                        <p>Lorem ipsum dolor sit amet consetetur sadipscing elitr sed diam nonumy eirmod tempor invidunt ut labore dolore magna aliquyam erat sed diam voluptua at vero eos et accusam et justo.</p>
                        <p>Lorem ipsum dolor sit amet consetetur sadipscing elitr sed diam nonumy eirmod tempor invidunt ut labore dolore magna.</p>
                        <div class="products-meta">
                            <span>SKU: <span class="sku">321HU3</span></span>
                            <span>Category: <a href="#">Book</a></span>
                            <span>Availability: <span class="in-stock">In Stock</span></span>
                        </div>
                        <div class="products-add-to-cart align-items-center">
                            <!-- <div class="input-counter">
                                 <span 
                                    @click="decrement"
                                    class="minus-btn"
                                >
                                    <i class='ri-subtract-line'></i>
                                </span>
                                <input type="text" :value="itemNumber">
                                <span 
                                    class="plus-btn"
                                    @click="increment"
                                >
                                    <i class='ri-add-line'></i>
                                </span>
                            </div> -->
                            <button 
                                type="submit" 
                                class="default-btn"
                                @click="addToCart({
                                    id: '1',
                                    name: 'Technology Book',
                                    price: 13.25,
                                    sale: false,
                                    new: false,
                                    image: require('../../assets/images/products/products-1.jpg'),
                                })"
                            >
                                <span>Add to Cart</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="products-details-tabs">
                <div class="tab-content">
                    <h2>Description</h2>
                    <div class="content">
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit id diam laoreet fringilla etiam pretium semper non nibh sagittis lorem sagittis nisi ac hendrerit lorem sapien viverra feugiat purus est velit consectetur itudin aliquet erat imperdiet lorem ipsum dolor sit amet consectetur adipiscing elit id diam laoreet fringilla tiam pretium semper non nibh sagittis lorem sagittis nisi ac hendrerit lorem sapien viverra.</p>
                        <p>Lorem ipsum dolor sit amet consetetur sadipscing elitr sed diam nonumy eirmod tempor invidunt ut labore dolore magna aliquyam erat sed diam voluptua.</p>
                    </div>
                    <h2>Reviews (1)</h2>
                    <div class="products-reviews">
                        <h3>Products Rating</h3>
                        <div class="rating">
                            <span class="ri-star-fill checked"></span>
                            <span class="ri-star-fill checked"></span>
                            <span class="ri-star-fill checked"></span>
                            <span class="ri-star-fill checked"></span>
                            <span class="ri-star-fill"></span>
                        </div>
                        <div class="rating-count">
                            <span>4.1 average based on 4 reviews.</span>
                        </div>
                        <div class="row">
                            <div class="side">
                                <div>5 star</div>
                            </div>
                            <div class="middle">
                                <div class="bar-container">
                                    <div class="bar-5"></div>
                                </div>
                            </div>
                            <div class="side right">
                                <div>02</div>
                            </div>
                            <div class="side">
                                <div>4 star</div>
                            </div>
                            <div class="middle">
                                <div class="bar-container">
                                    <div class="bar-4"></div>
                                </div>
                            </div>
                            <div class="side right">
                                <div>03</div>
                            </div>
                            <div class="side">
                                <div>3 star</div>
                            </div>
                            <div class="middle">
                                <div class="bar-container">
                                    <div class="bar-3"></div>
                                </div>
                            </div>
                            <div class="side right">
                                <div>04</div>
                            </div>
                            <div class="side">
                                <div>2 star</div>
                            </div>
                            <div class="middle">
                                <div class="bar-container">
                                    <div class="bar-2"></div>
                                </div>
                            </div>
                            <div class="side right">
                                <div>05</div>
                            </div>
                            <div class="side">
                                <div>1 star</div>
                            </div>
                            <div class="middle">
                                <div class="bar-container">
                                    <div class="bar-1"></div>
                                </div>
                            </div>
                            <div class="side right">
                                <div>00</div>
                            </div>
                        </div>
                    </div>

                    <div class="products-review-comments">
                        <h3>Reviews</h3>
                        <div class="user-review">
                            <img src="../../assets/images/products/image-1.jpg" alt="image">
                            
                            <div class="review-rating">
                                <div class="review-stars">
                                    <i class='ri-star-fill checked'></i>
                                    <i class='ri-star-fill checked'></i>
                                    <i class='ri-star-fill checked'></i>
                                    <i class='ri-star-fill checked'></i>
                                    <i class='ri-star-fill checked'></i>
                                </div>

                                <span class="d-inline-block">James Anderson</span>
                            </div>

                            <span class="d-block sub-comment">Excellent</span>
                            <p>Very well built theme, couldn't be happier with it. Can't wait for future updates to see what else they add in.</p>
                        </div>
                    </div>

                    <div class="review-form-wrapper">
                        <h3>Add a Review</h3>
                        <p class="comment-notes">Your email address will not be published. Required fields are marked <span>*</span></p>

                        <form>
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <div class="rating">
                                        <input type="radio" id="star5" name="rating" value="5" /><label for="star5"></label>
                                        <input type="radio" id="star4" name="rating" value="4" /><label for="star4"></label>
                                        <input type="radio" id="star3" name="rating" value="3" /><label for="star3"></label>
                                        <input type="radio" id="star2" name="rating" value="2" /><label for="star2"></label>
                                        <input type="radio" id="star1" name="rating" value="1" /><label for="star1"></label>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Name *">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="email" class="form-control" placeholder="Email *">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea placeholder="Your review" class="form-control" cols="30" rows="6"></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <p class="comment-form-cookies-consent">
                                        <input type="checkbox" id="test">
                                        <label for="test">Save my name, email, and website in this browser for the next time I comment.</label>
                                    </p>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn">
                                        <span>Submit</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProductsDetails',
    data() {
        return {
            itemNumber: 1,
        }
    },
    methods: {
        increment(){
            if(this.itemNumber < 1){
                this.itemNumber++
            } else {
                this.$toast.success('Can\'t add more than 1 items', {
                    icons: 'shopping_cart',
                    theme: "bubble", 
                    position: "bottom-right", 
                    duration : 3000
                });
            }
        },
        decrement(){
            if(this.itemNumber > 1){
                this.itemNumber--
            } else {
                this.$toast.success('Can\'t add less than 1 item', {
                    icons: 'shopping_cart',
                    theme: "bubble",
                    position: "top-right",
                    duration : 3000
                });
            }
        },
        addToCart(item) {
            this.$toast.success('Added to cart', {
                icons: 'shopping_cart',
                position: "bottom-left", 
                duration : 3000
            });
            if(this.size !== '') {
                this.$store.commit({
                    type: 'addToCart',
                    id: item.id,
                    name: item.name,
                    price: item.price,
                    oldPrice: item.oldPrice,
                    image: item.image,
                    productQuantity: this,
                    totalPrice: item.price,
                })
            }
        }
    }
}
</script>