<template>
    <div class="overview-area pt-5 pb-5">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-3 col-md-6">
                    <div class="overview-card">
                        <h3>Telefon</h3>
                        <span>
                            <a href="tel:+4946199582981">+49 461 99582981</a>
                        </span>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="overview-card">
                        <h3>E-Mail Adresse</h3>
                        <span>
                            <a href="mailto:mail@leifpaisen.de">leif@lp-its.de</a>
                        </span>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="overview-card">
                        <h3>Notfälle</h3>
                        <span>
                            <a href="tel:+491719586622">+49 171 958 6622</a>
                        </span>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="overview-card">
                        <h3>Adresse</h3>
                        <span>Dorfstraße 8b, 25885 Immenstedt</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Overview'
}
</script>