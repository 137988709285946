<template>
    <div class="testimonials-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="testimonials-section-content" data-aos="fade-right" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                        <span>TESTIMONIALS</span>
                        <h3>Some <b>Sweet Talk</b> Of Our Happy Clients</h3>
                        <p>We are leading technology solutions providing company all over the world doing over 40 years lorem ipsum dolor sit amet.</p>
                        <p>We are leading technology solutions providing company all over the world doing over 40 years. lorem ipsum dolor sit amet consetetur.</p>

                        <div class="testimonials-btn">
                            <router-link to="/about-style-1" class="default-btn">Know More About Us</router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="testimonials-item" data-aos="fade-left" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                        <div class="item-box" data-tilt>
                            <img src="../../assets/images/testimonials/testimonials-1.jpg" class="rounded-circle" alt="image">
                            <p>Lorem ipsum dolor sit amet consetetur sadips cin go elitr sed diam nonumy eirmod.</p>
                            <h4>Herry Hopper, <span>Solit Team</span></h4>
                        </div>

                        <div class="item-box" data-tilt>
                            <img src="../../assets/images/testimonials/testimonials-2.jpg" class="rounded-circle" alt="image">
                            <p>Lorem ipsum dolor sit amet consetetur sadips cin go elitr sed diam nonumy eirmod.</p>
                            <h4>Daniel John, <span>Spix Team</span></h4>
                        </div>

                        <div class="item-box" data-tilt>
                            <img src="../../assets/images/testimonials/testimonials-3.jpg" class="rounded-circle" alt="image">
                            <p>Lorem ipsum dolor sit amet consetetur sadips cin go elitr sed diam nonumy eirmod.</p>
                            <h4>Zinnia, <span>Koze Team</span></h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="testimonialsbg-shape">
            <img src="../../assets/images/testimonials/testimonials-shape.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'Testimonials'
}
</script>