<template>
    <div class="testimonials-area ptb-100">
        <div class="container">
            <div class="section-title">
                <span>TESTIMONIALS</span>
                <h2>Our Client's Feedback</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6">
                    <div class="single-testimonials-card" data-aos="fade-up" data-aos-delay="50" data-aos-duration="500" data-aos-once="true">
                        <p>We look and sound so good! I am still in shock at how smooth this process was. The professionalism, collaboration and the design they come up is great.</p>

                        <div class="info-item-box">
                            <img src="../../assets/images/testimonials/testimonials-4.jpg" class="rounded-circle" alt="image">
                            <h4>Bradly Doe, <span>Founder of Medizo</span></h4>
                            <ul class="rating-list">
                                <li><i class="ri-star-fill"></i></li>
                                <li><i class="ri-star-fill"></i></li>
                                <li><i class="ri-star-fill"></i></li>
                                <li><i class="ri-star-fill"></i></li>
                                <li><i class="ri-star-line"></i></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-testimonials-card" data-aos="fade-up" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                        <p>The professionalism, collaboration and the design they come up with is pheno-menal. Thanks a lot the Koze Team.</p>

                        <div class="info-item-box">
                            <img src="../../assets/images/testimonials/testimonials-5.jpg" class="rounded-circle" alt="image">
                            <h4>Daniel John, <span>Solit Team</span></h4>
                            <ul class="rating-list">
                                <li><i class="ri-star-fill"></i></li>
                                <li><i class="ri-star-fill"></i></li>
                                <li><i class="ri-star-fill"></i></li>
                                <li><i class="ri-star-fill"></i></li>
                                <li><i class="ri-star-line"></i></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-testimonials-card" data-aos="fade-up" data-aos-delay="60" data-aos-duration="600" data-aos-once="true">
                        <p>We look and sound so good! I am still in shock at how smooth this process was. The professionalism, collaboration and the design they come up is great.</p>

                        <div class="info-item-box">
                            <img src="../../assets/images/testimonials/testimonials-6.jpg" class="rounded-circle" alt="image">
                            <h4>Jennifer Smith, <span>Spix Team</span></h4>
                            <ul class="rating-list">
                                <li><i class="ri-star-fill"></i></li>
                                <li><i class="ri-star-fill"></i></li>
                                <li><i class="ri-star-fill"></i></li>
                                <li><i class="ri-star-fill"></i></li>
                                <li><i class="ri-star-line"></i></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-testimonials-card" data-aos="fade-up" data-aos-delay="70" data-aos-duration="700" data-aos-once="true">
                        <p>The professionalism, collaboration and the design they come up with is pheno-menal. Thanks a lot the Koze Team.</p>

                        <div class="info-item-box">
                            <img src="../../assets/images/testimonials/testimonials-7.jpg" class="rounded-circle" alt="image">
                            <h4>Sarp Karahan, <span>Benzo Team</span></h4>
                            <ul class="rating-list">
                                <li><i class="ri-star-fill"></i></li>
                                <li><i class="ri-star-fill"></i></li>
                                <li><i class="ri-star-fill"></i></li>
                                <li><i class="ri-star-fill"></i></li>
                                <li><i class="ri-star-line"></i></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-testimonials-card" data-aos="fade-up" data-aos-delay="90" data-aos-duration="900" data-aos-once="true">
                        <p>We look and sound so good! I am still in shock at how smooth this process was. The professionalism, collaboration and the design they come up is great.</p>

                        <div class="info-item-box">
                            <img src="../../assets/images/testimonials/testimonials-8.jpg" class="rounded-circle" alt="image">
                            <h4>Jane Ronan, <span>Lebu Team</span></h4>
                            <ul class="rating-list">
                                <li><i class="ri-star-fill"></i></li>
                                <li><i class="ri-star-fill"></i></li>
                                <li><i class="ri-star-fill"></i></li>
                                <li><i class="ri-star-fill"></i></li>
                                <li><i class="ri-star-line"></i></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-testimonials-card" data-aos="fade-up" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                        <p>The professionalism, collaboration and the design they come up with is pheno-menal. Thanks a lot the Koze Team.</p>

                        <div class="info-item-box">
                            <img src="../../assets/images/testimonials/testimonials-1.jpg" class="rounded-circle" alt="image">
                            <h4>Johny Smith, <span>Solit Team</span></h4>
                            <ul class="rating-list">
                                <li><i class="ri-star-fill"></i></li>
                                <li><i class="ri-star-fill"></i></li>
                                <li><i class="ri-star-fill"></i></li>
                                <li><i class="ri-star-fill"></i></li>
                                <li><i class="ri-star-line"></i></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <div class="pagination-area">
                        <router-link to="/testimonials" class="prev page-numbers"><i class="ri-arrow-left-s-line"></i></router-link>
                        <span class="page-numbers current" aria-current="page">1</span>
                        <router-link to="/testimonials" class="page-numbers">2</router-link>
                        <router-link to="/testimonials" class="page-numbers">3</router-link>
                        <router-link to="/testimonials" class="next page-numbers"><i class="ri-arrow-right-s-line"></i></router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Testimonials'
}
</script>